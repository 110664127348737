import React from 'react';
import { Footer2Button } from '../Components/Footers';

export default function userVerification() {
  return (
    <div className="px-4 md:px-0">
      <div className="bg-white py-8 px-4 mt-8 rounded-lg md:mx-36">
        <h1 className="text-gray-750 font-bold text-xl">Registration for “Tournament 2021”</h1>
        <p className="text-gray-750 mt-2 text-sm">Verification</p>
        <p className="text-gray-850 mt-8 text-sm">Govt Id</p>
        <p className="text-gray-550 text-xs">
          Upload legal government ID proof to verify your identity.
        </p>
      </div>
      <Footer2Button />
    </div>
  );
}
